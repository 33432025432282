const imageElement = document.getElementById('home-hero');

const toTopBtn = document.getElementById('to-top');
const observer = new IntersectionObserver((entries) => {
  if (!entries[0].isIntersecting) {
    toTopBtn.classList.add('show');
  } else {
    toTopBtn.classList.remove('show');
  }
});
observer.observe(imageElement);

function stopMovie() {
  const iframe = document.getElementById('b2zkqeyUqB8');
  const source = iframe.getAttribute('src');
  // stripping out the source stops the video.
  // It is then added back in so the video is ready if clicked again
  iframe.setAttribute('src', '');
  iframe.setAttribute('src', source);
}

// stops video when modal close button is clicked
const videoClose = document.querySelector('.usa-modal__close');
videoClose.addEventListener('click', stopMovie);

// stops movie when modal is clicked out of and modal closes. This watches for changes to
// main content div and the aria hidden attribute is applied when the modal is open. We can't
// watch for the modal because it does not exist on the DOM until it is open
const mainContent = document.getElementById('main-content');

const modalObserver = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'attributes') {
      if (!mutation.target.getAttribute('aria-hidden')) {
        stopMovie();
      }
    }
  });
});

const observerOptions = {
  attributes: true,
};

modalObserver.observe(mainContent, observerOptions);
